/* eslint-disable import/named */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';
import { Feed } from './feed';
import { Picture } from './topic';

import axiosInstance from './request';

export interface DiscoverListRequest {
    searchWord?: string;
    pcursor?: number | string;
    mobile?: boolean;
    fromUser?: boolean;
    count?: number;
    request_source?: number;
    seoDefaultFilterV2?: boolean;
}

export interface DiscoverAIContent {
    title?: string;
    description?: string;
    keywords?: Array<string>;
    askmore?: string;
    askmore_bot_id?: string;
    askmore_bot_head_url?: string;
}

export type DiscoverItem = Feed & Picture;

export interface NewDiscoverListRequest {
    searchWord?: string;
    pcursor?: number | string;
    mobile?: boolean;
    fromUser?: boolean;
    count?: number;
    request_source?: number;
    seoDefaultFilterV2?: boolean;
    url?: string;
    need_tdk?: boolean;
    need_ld_json?: boolean;
    ai_content?: boolean;
}

interface NewDiscoverListResponse extends ResponseBase {
    feeds?: Array<DiscoverItem>;
    ldjson?: Array<any>;
    tdk?: Array<any>;
    from?: string; // 后端记录下发的 feed 来源
    ai_content?: DiscoverAIContent;
}

interface DiscoverListResponse extends ResponseBase {
    pcursor?: string | number;
    feeds?: Array<DiscoverItem>;
}

interface reportDiscoverVideoViewLog {
    pidList?: string[];
}

export class DiscoverApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getDiscoverList(data: DiscoverListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<DiscoverListResponse>('overseaAPI::/rest/o/w/pwa/discover', data, config);

        return res;
    }

    async getNewDiscoverList(data: NewDiscoverListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<NewDiscoverListResponse>('overseaAPI::/rest/o/w/pwa/v2/search/word/outer', data, config);

        return res;
    }

    async reportDiscoverViewLog(data: reportDiscoverVideoViewLog, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ResponseBase>('/rest/o/w/pwa/v2/view/log', data, config);

        return res;
    }
}

export const DiscoverApi = new DiscoverApiService();
