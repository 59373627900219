/* eslint-disable import/named */
import { NuxtAxiosInstance } from 'vue/types/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosRequestConfig } from 'axios';
import { CdnWithUrl, RequestSource, ResponseBase, PWAFromPage } from './base';

import axiosInstance from './request';

export enum FeedLiked {
    UnLike = 0,
    Like = 1,
}

export enum AuthorFollowing {
    Unfollowed = 0,
    Following = 1,
}

interface ForwardStatsParams {
    fid?: string;
    et?: string;
}

export interface UserComment {
    commentId?: number;
    content?: string;
    commentTime?: number;
    userId?: number;
    userName?: string;
    userHead?: string;
    userHeads?: Array<CdnWithUrl>;
    commentLikeCount?: number;
    kwaiid?: string;
}

export interface TranscodeManifestInfo {
    businessType?: number;
    hideAuto?: boolean;
    manualDefaultSelect?: boolean;
    mediaType?: number;
    videoId?: string;
    version?: string;
    adaptationSet?: Array<{
        duration?: number;
        representation?: Array<{
            url?: string;
            width?: number;
            height?: number;
        }>;
    }>;
}

export interface Feed {
    web_feed_type?: string;
    time?: string;
    timestamp?: number;
    liked?: FeedLiked;
    following?: AuthorFollowing;
    photo_id?: number;
    photo_id_str?: string;
    user_name?: string;
    user_sex?: string;
    kwai_id?: string;
    user_text?: string;
    comment_count?: number;
    forward_count?: number;
    download_count?: number;
    headurls?: Array<CdnWithUrl>;
    main_mv_urls?: Array<CdnWithUrl>;
    transcode_manifest_info?: TranscodeManifestInfo; // 265 播放器视频源信息，优先取这个字段，用 main_mv_urls 兜底
    cover_thumbnail_urls?: Array<CdnWithUrl>;
    cover_first_frame_urls?: Array<CdnWithUrl>;
    cover_urls?: Array<CdnWithUrl>;
    reco_reason?: string;
    exp_tag?: string;
    ext_params?: Record<string, any>;
    like_count?: number;
    view_count?: number;
    forward_stats_params?: ForwardStatsParams;
    caption?: string;
    keywords?: Array<string>;
    user_id?: number | string;
    user_id_str?: string;
    comment?: UserComment;
    verifiedNum?: number;
    source_type?: string; // 后续添加其他来源， 目前只识别 'reco'
    from?: string; // 后端记录下发的 feed 来源
    // 前端添加，google 广告标识
    google_ad?: boolean;
    // 前端添加，商业化广告标识
    lumina_ad?: boolean;
    // 前端添加 AI 导流标识，出 plc
    ai_ad_guide?: boolean;
    // 前端添加 AI Card
    ai_chat_card?: boolean;
}

/** *** Api  *** */
export interface FeedListRequest {
    photo_id_str?: string;
    deviceId?: string;
    request_source?: RequestSource;
    count?: number;
    mobile?: boolean;
}

interface FeedListResponse extends ResponseBase {
    feeds?: Array<Feed>;
    photoNotFound?: boolean;
}

export interface DiscoverSearchListRequest extends Pick<FeedListRequest, 'count' | 'mobile'> {
    requestSource: RequestSource;
    photoIdStr?: string;
    url: string;
    onlyTitle?: boolean;
    needLdJson?: boolean;
    needTdk?: boolean;
}

export interface DiscoverSearchListResponse extends FeedListResponse {
    tdk?: any[];
    ldjson?: any[];
}

interface UserCommentListRequest {
    photo_id?: string;
}

interface UserCommentListResponse extends ResponseBase {
    comments?: Array<UserComment>;
}

interface ChangeFeedLikedStatusRequest {
    fromPage?: string;
    cancel?: boolean;
    photoId?: string;
}

type ChangeFeedLikedStatusResponse = ResponseBase;

interface FollowActionRequest {
    toUid?: string | number; // 可以是 user_id, 也可以是 `@${kwai_id}`
    fType?: number;
    photoId?: string;
    noticeId?: string;

    /**
     * @zh ===用于风控 START===
     */
    fromPage?: PWAFromPage;
    /**
     * @zh contentType
     * profile: profile 页等
     * photo: 视频上下滑等场景
     */
    contentType?: 'profile' | 'photo';
    contentId?: string;
    sourcePageTag?: string; // 当前页面 url，排查 query
    /**
     * @zh ===用于风控 END===
     */
}

type FollowActionResponse = ResponseBase;

export class FeedApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getFeedList(data: FeedListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<FeedListResponse>('overseaAPI::/rest/o/w/pwa/feed/slides', data, config);

        return res;
    }

    async getDiscoverSearchFeedList(data: DiscoverSearchListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<DiscoverSearchListResponse>('overseaAPI::/rest/o/w/pwa/v2/search/feed', data, config);

        return res;
    }

    async getUserCommentList(data: UserCommentListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$get?.<UserCommentListResponse>('/rest/o/w/pwa/comment/list', {
            ...config,
            params: data,
        });

        return res;
    }

    async changeFeedLikedStatus(data: ChangeFeedLikedStatusRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ChangeFeedLikedStatusResponse>('/rest/o/w/pwa/likeAction', data, config);

        return res;
    }

    async followAction(data: FollowActionRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<FollowActionResponse>('/rest/o/w/pwa/followAction', data, config);

        return res;
    }
}

export const FeedApi = new FeedApiService();
