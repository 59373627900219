import { render, staticRenderFns } from "./ios-feed-list.vue?vue&type=template&id=6e5e4aa5&scoped=true&"
import script from "./ios-feed-list.vue?vue&type=script&lang=ts&"
export * from "./ios-feed-list.vue?vue&type=script&lang=ts&"
import style0 from "./ios-feed-list.vue?vue&type=style&index=0&id=6e5e4aa5&prod&lang=stylus&scoped=true&"
import style1 from "./ios-feed-list.vue?vue&type=style&index=1&id=6e5e4aa5&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e5e4aa5",
  null
  
)

export default component.exports